import { z } from "zod";

import { PostType } from "~/enums";
import { contentBlockSchema } from "~/features/learning/api/stage/stage.schema";
import { featuredMediaSchema } from "~/lib/schemas/featured-media";
import { titleSchema } from "~/lib/schemas/title";

const resourceSchema = z.object({
	commentsAllowed: z.boolean().catch(true),
	content: z.array(contentBlockSchema),
	featuredMedia: featuredMediaSchema.nullable().optional(),
	id: z.number(),
	title: titleSchema,
	type: z.literal(PostType.RESOURCE),
});

type ResourceResponse = z.infer<typeof resourceSchema>;

export { resourceSchema, type ResourceResponse };
