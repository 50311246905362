import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	queryOptions,
	useSuspenseQuery,
} from "@tanstack/react-query";

import { getStage, getUrl, type GetStageResponse } from "./stage.api";
import { type StageResponse, type QuizData, type Question } from "./stage.schema";

type LearningQueryKey<TGroup extends string, TParams extends Record<string, number | undefined>> = [
	"learning",
	TGroup,
	TParams,
];

const getQueryKey = (id: number): LearningQueryKey<"stage", { id: number }> => ["learning", "stage", { id }];

const getBaseQueryOptions = <TData = GetStageResponse>(id: number) =>
	queryOptions<GetStageResponse, Error, TData>({
		queryFn: () => getStage(id),
		queryKey: getQueryKey(id),
	});

const useStageSuspenseQuery = <TData = GetStageResponse>(
	id: number,
	options?: Pick<UseSuspenseQueryOptions<GetStageResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	useSuspenseQuery<GetStageResponse, Error, TData>({
		...getBaseQueryOptions(id),
		...options,
	});

export {
	useStageSuspenseQuery,
	getBaseQueryOptions as getStageBaseQueryOptions,
	getQueryKey as getStageQueryKey,
	getUrl as getStageUrl,
	type StageResponse,
	type QuizData,
	type Question,
};
