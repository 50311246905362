import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";

import { type ResourceCategoryResponse, resourceCategorySchema } from "./category.schema";

const getUrl = (id: number) => buildUrl(`resource-category/${id}`, 1);

const getResourceCategory = (id: number): Promise<ResourceCategoryResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.get(getUrl(id))
		.json(resourceCategorySchema.parseAsync);

type GetResourceCategoryResponse = Awaited<ReturnType<typeof getResourceCategory>>;

export { getResourceCategory, getUrl, type GetResourceCategoryResponse };
