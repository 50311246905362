import { FeatureFlags } from "@evotix/napier-ui-common-components";

import { type DynamicGlobalConfig, type DynamicGlobalConfigFeatures } from "./config.schema";

export class GlobalConfig {
	static configUrl = "/config.json";

	#config: null | DynamicGlobalConfig = null;

	public get() {
		if (!this.#config) {
			throw new Error("Global config has not been defined yet.");
		}
		return this.#config;
	}

	public set(value: DynamicGlobalConfig) {
		if (this.#config) {
			throw new Error("Global config has already been defined");
		}

		this.#config = value;
	}

	public isFeatureEnabled(featureKey: DynamicGlobalConfigFeatures) {
		return FeatureFlags.isFeatureEnabled(this.get().featureFlags, featureKey);
	}
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = GlobalConfig.configUrl;

export * from "./config.schema";

export * from "./components/Provider";
