import {
	queryOptions,
	useSuspenseQuery as rqUseSuspenseQuery,
	useQuery as rqUseQuery,
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	type UseQueryOptions,
	type UseQueryResult,
} from "@tanstack/react-query";

import { PostGroup } from "~/enums";
import { type ParentBase } from "~/types/application";

import { getPathways, getUrl, type GetPathwaysResponse } from "./pathways.api";
import { type PathwaysResponse } from "./pathways.schema";

type PathwaysParent = ParentBase<PostGroup.PATHWAYS, typeof getBaseQueryOptions, typeof getUrl>;
type Tuple = [pathways: PathwaysParent];

const getQueryKey = () => ["learning", "pathways"] as const;

const getBaseQueryOptions = <TData = GetPathwaysResponse>() =>
	queryOptions<GetPathwaysResponse, Error, TData>({ queryFn: getPathways, queryKey: getQueryKey() });

const useQuery = <TData = GetPathwaysResponse>(
	options?: Pick<UseQueryOptions<GetPathwaysResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	rqUseQuery<GetPathwaysResponse, Error, TData>({
		...getBaseQueryOptions(),
		...options,
	});

const useSuspenseQuery = <TData = GetPathwaysResponse>(
	options?: Pick<UseSuspenseQueryOptions<GetPathwaysResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	rqUseSuspenseQuery<GetPathwaysResponse, Error, TData>({
		...getBaseQueryOptions(),
		...options,
	});

const getParent = () =>
	({
		getQueryOptions: getBaseQueryOptions,
		getUrl,
		type: PostGroup.PATHWAYS,
	}) satisfies PathwaysParent;

export {
	useQuery as usePathwaysQuery,
	useSuspenseQuery as usePathwaysSuspenseQuery,
	getBaseQueryOptions as getPathwaysBaseQueryOptions,
	getQueryKey as getPathwaysQueryKey,
	getParent as getPathwaysParent,
	getUrl as getPathwaysUrl,
	type PathwaysResponse,
	type Tuple as PathwaysTuple,
};
