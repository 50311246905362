import { type WretchError } from "wretch";

import { TENANT_KEY_HEADER_KEY } from "~/features/application/constants";
import { buildUrl } from "~/features/application/utilities";
import { coreWretch, wretch } from "~/lib/external/wretch";

import { heartbeatResponseSchema } from "./heartbeat.schema";

const getUrl = () => buildUrl("auth/heartbeat", 1);

const getHeartbeat = () =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.get(getUrl())
		.json(heartbeatResponseSchema.parse);

const getNativeHeartbeat = (
	tenant: string,
	token: string,
	onUnauthorised: (error: WretchError) => Promise<WretchError>,
) =>
	coreWretch()
		.headers({
			"Content-Type": "application/json",
			[TENANT_KEY_HEADER_KEY]: tenant,
		})
		.auth(`Bearer ${token}`)
		.get(getUrl())
		.unauthorized(onUnauthorised)
		.json(heartbeatResponseSchema.safeParse);

type GetHeartbeatResponse = Awaited<ReturnType<typeof getHeartbeat>>;

export { getHeartbeat, getNativeHeartbeat, getUrl, type GetHeartbeatResponse };
