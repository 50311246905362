import { type QueryClient } from "@tanstack/react-query";

import { getResourceCategoriesBaseQueryOptions } from "../api/categories";
import { getLatestResourcesBaseQueryOptions } from "../api/latest-resources";

export const prefetchResourceData = async (queryClient: QueryClient) => {
	await Promise.allSettled([
		queryClient.prefetchQuery(getResourceCategoriesBaseQueryOptions()),
		queryClient.prefetchQuery(getLatestResourcesBaseQueryOptions()),
	]);
};
