import { decode } from "entities";

/**
 * Decodes special characters in a string into HTML entities.
 *
 * This function is useful for sanitising strings that may contain
 * special characters (like &, <, >) before displaying them.
 *
 * @param {string} str - The string to encode.
 * @returns {string} The encoded string with special characters converted to HTML entities.
 * @example
 * const decoded = decodeEntities("Hello &amp; welcome!");
 * console.log(decoded); // Outputs: "Hello & welcome!"
 *
 */
export const decodeEntities = (str: string) => decode(str);
