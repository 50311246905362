import { createRoute, lazyRouteComponent } from "@tanstack/react-router";

import { prefetchApiData } from "~/features/application/data-prefetch";

import { privateRoute } from "./private";

export const applicationLayout = createRoute({
	component: lazyRouteComponent(() => import("~/features/application/layouts/Main"), "MainLayout"),
	getParentRoute: () => privateRoute,
	id: "application-layout",
	loader: async ({ context }) => {
		// If we get here, we must have a valid tenant and an authenticated user, who has accepted the terms and conditions or privacy policies
		// We can now prefetch all the static data we need for the application
		await prefetchApiData(context.queryClient);
	},
});
