import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	useSuspenseQuery as useBaseSuspenseQuery,
	queryOptions,
} from "@tanstack/react-query";

import { PostType } from "~/enums";
import { type Parent } from "~/types/application";

import { type GetResourceCategoryResponse, getResourceCategory, getUrl } from "./category.api";
import { type ResourceCategoryResponse } from "./category.schema";

type ResourceCategoryParent = Parent<PostType.RESOURCE_CATEGORY, typeof getBaseQueryOptions, typeof getUrl>;
type Tuple = [resourceCategoryResponse: ResourceCategoryParent];

const getQueryKey = (id: number) => ["resources", "category", { id }] as const;

const getBaseQueryOptions = <TData = GetResourceCategoryResponse>(id: number) =>
	queryOptions<GetResourceCategoryResponse, Error, TData>({
		queryFn: () => getResourceCategory(id),
		queryKey: getQueryKey(id),
	});

const useSuspenseQuery = <TData = GetResourceCategoryResponse>(
	id: number,
	options?: Pick<UseSuspenseQueryOptions<GetResourceCategoryResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> => {
	return useBaseSuspenseQuery<GetResourceCategoryResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(id),
	});
};

const getParent = (id: number) =>
	({
		getQueryOptions: getBaseQueryOptions,
		getUrl,
		id,
		type: PostType.RESOURCE_CATEGORY,
	}) satisfies ResourceCategoryParent;

export {
	useSuspenseQuery as useResourceCategorySuspenseQuery,
	getBaseQueryOptions as getResourceCategoryBaseQueryOptions,
	getQueryKey as getResourceCategoryKey,
	getParent as getResourceCategoryParent,
	getUrl as getResourceCategoryUrl,
	type Tuple as ResourceCategoryTuple,
	type ResourceCategoryResponse,
};
