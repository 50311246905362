import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	queryOptions,
	useQuery as rqUseQuery,
	useSuspenseQuery as rqUseSuspenseQuery,
	type UseQueryResult,
	type UseQueryOptions,
} from "@tanstack/react-query";

import { PostType } from "~/enums";
import { type Parent } from "~/types/application";

import { getLevel, getUrl, type GetLevelResponse } from "./level.api";

type LevelParent = Parent<PostType.LEVEL, typeof getBaseQueryOptions, typeof getUrl>;
type Tuple = [level: LevelParent];

const getBaseQueryKey = () => ["learning", "level"] as const;
const getQueryKey = (id: number) => [...getBaseQueryKey(), { id }] as const;

const getBaseQueryOptions = <TData = GetLevelResponse>(id: number) =>
	queryOptions<GetLevelResponse, Error, TData>({
		queryFn: () => getLevel(id),
		queryKey: getQueryKey(id),
	});

const useQuery = <TData = GetLevelResponse>(
	id: number,
	options?: Pick<UseQueryOptions<GetLevelResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	rqUseQuery<GetLevelResponse, Error, TData>({
		...getBaseQueryOptions(id),
		...options,
	});

const useSuspenseQuery = <TData = GetLevelResponse>(
	id: number,
	options?: Pick<UseSuspenseQueryOptions<GetLevelResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	rqUseSuspenseQuery<GetLevelResponse, Error, TData>({
		...getBaseQueryOptions(id),
		...options,
	});

const getParent = (id: number) =>
	({
		getQueryOptions: getBaseQueryOptions,
		getUrl,
		id,
		type: PostType.LEVEL,
	}) satisfies LevelParent;

export {
	useQuery as useLevelQuery,
	useSuspenseQuery as useLevelSuspenseQuery,
	getBaseQueryOptions as getLevelBaseQueryOptions,
	getQueryKey as getLevelQueryKey,
	getBaseQueryKey as getLevelBaseQueryKey,
	getParent as getLevelParent,
	getUrl as getLevelUrl,
	type Tuple as LevelTuple,
};
