import { z } from "zod";

import { PostType } from "~/enums";
import { cacheableMediaUrlSchema } from "~/lib/schemas/cacheable-media-url";
import { titleSchema } from "~/lib/schemas/title";

import { sharedResourceSchema } from "../shared.schema";

const resourceCategorySchema = sharedResourceSchema.extend({
	children: z.array(
		z.object({
			id: z.number(),
			thumbnailUrl: cacheableMediaUrlSchema().nullable(),
			title: titleSchema,
			type: z.literal(PostType.RESOURCE).catch(PostType.RESOURCE),
		}),
	),
	type: z.literal(PostType.RESOURCE_CATEGORY).catch(PostType.RESOURCE_CATEGORY),
});

type ResourceCategoryResponse = z.infer<typeof resourceCategorySchema>;

export { resourceCategorySchema, type ResourceCategoryResponse };
