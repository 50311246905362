import { ActivityProgress, ActivitySourceType, PostType } from "~/enums";
import { type ActivityTrack } from "~/features/activities/api";
import { type PostActivityVariables } from "~/features/activities/api/activities.schema";
import { type CompletedLearningResponse } from "~/features/activities/completed-learning/api";
import { type LearningGroupResponse } from "~/features/learning/api/learning.schema";
import { type OptionalSMIProp } from "~/features/learning/api/shared.schema";
import { dayjs } from "~/lib/external/day-js";
import { type Prettify } from "~/types/application";

export type ActivityPostSource = Exclude<`${PostType}`, `${PostType.RESOURCE}` | `${PostType.RESOURCE_CATEGORY}`>;

type PostItem = Prettify<
	Pick<LearningGroupResponse | LearningGroupResponse["children"][number], "id" | "points"> &
		OptionalSMIProp & {
			type: ActivityPostSource;
		} & Pick<PostActivityVariables, "title">
>;

export type ActivityTrackWithoutBadgeSource = Omit<ActivityTrack, "source"> &
	Pick<PostActivityVariables, "title"> & {
		source: Exclude<`${ActivitySourceType}`, `${ActivitySourceType.BADGE}`>;
	};

const PostTypeToActivitySource: Record<ActivityPostSource, ActivitySourceType> = {
	[PostType.ARTICLE]: ActivitySourceType.ARTICLE,
	[PostType.AUDIO_VISUAL]: ActivitySourceType.AUDIO_VISUAL,
	[PostType.PDF]: ActivitySourceType.PDF,
	[PostType.QUIZ]: ActivitySourceType.QUIZ,
	[PostType.SCORM]: ActivitySourceType.SCORM,
	[PostType.PATHWAY]: ActivitySourceType.PATHWAY,
	[PostType.LEVEL]: ActivitySourceType.LEVEL,
	[PostType.MISSION]: ActivitySourceType.MISSION,
};

const ActivitySourceToPostType: Record<ActivityTrackWithoutBadgeSource["source"], ActivityPostSource> = {
	[ActivitySourceType.ARTICLE]: PostType.ARTICLE,
	[ActivitySourceType.AUDIO_VISUAL]: PostType.AUDIO_VISUAL,
	[ActivitySourceType.PDF]: PostType.PDF,
	[ActivitySourceType.QUIZ]: PostType.QUIZ,
	[ActivitySourceType.SCORM]: PostType.SCORM,
	[ActivitySourceType.PATHWAY]: PostType.PATHWAY,
	[ActivitySourceType.LEVEL]: PostType.LEVEL,
	[ActivitySourceType.MISSION]: PostType.MISSION,
};

export const toStartActivity = <TData extends Omit<PostItem, "points"> = Omit<PostItem, "points">>({
	id,
	smi,
	type,
	title,
}: NoInfer<TData>) =>
	({
		activity: ActivityProgress.START,
		points: 0,
		post_id: id,
		smi: smi ?? 0,
		source: PostTypeToActivitySource[type],
		timestamp: dayjs().toISOString(),
		title,
	}) satisfies PostActivityVariables;

export const toCompleteActivity = <TData extends PostItem>({ points, id, smi, type, title }: TData) =>
	({
		activity: ActivityProgress.COMPLETE,
		points,
		post_id: id,
		smi: smi ?? 0,
		source: PostTypeToActivitySource[type],
		timestamp: dayjs().toISOString(),
		title,
	}) satisfies PostActivityVariables;

export const toAwardActivity = <TData extends Omit<PostItem, "type">>(badgeId: number, { points, id, smi }: TData) =>
	({
		activity: badgeId.toString(),
		points,
		post_id: id,
		smi: smi ?? 0,
		source: ActivitySourceType.BADGE,
		timestamp: dayjs().toISOString(),
	}) satisfies ActivityTrack;

export const toCompletedLearningItem = <TData extends ActivityTrackWithoutBadgeSource>({
	// eslint-disable-next-line camelcase -- it's set from server
	post_id,
	source,
	timestamp,
	title,
}: TData) =>
	({
		completedAt: timestamp,
		// eslint-disable-next-line camelcase -- it's set from server
		id: post_id,
		title: title ?? null,
		type: ActivitySourceToPostType[source],
	}) satisfies CompletedLearningResponse["items"][number];
