import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	useSuspenseQuery as useBaseSuspenseQuery,
	queryOptions,
} from "@tanstack/react-query";

import { type GetLatestResourcesResponse, getLatestResources } from "./latest-resources.api";

const getQueryKey = () => ["resources", "latest"] as const;

const getBaseQueryOptions = <TData = GetLatestResourcesResponse>() =>
	queryOptions<GetLatestResourcesResponse, Error, TData>({
		queryFn: () => getLatestResources(),
		queryKey: getQueryKey(),
	});

const useSuspenseQuery = <TData = GetLatestResourcesResponse>(
	options?: Pick<UseSuspenseQueryOptions<GetLatestResourcesResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> => {
	return useBaseSuspenseQuery<GetLatestResourcesResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});
};

export {
	useSuspenseQuery as useLatestResourcesSuspenseQuery,
	getBaseQueryOptions as getLatestResourcesBaseQueryOptions,
	getQueryKey as getLatestResourcesQueryKey,
};
