import { type QueryClient } from "@tanstack/react-query";

import { getProfileInterestsBaseQueryOptions } from "../api/profile";
import { getSiteInterestsBaseQueryOptions } from "../api/site";

export const prefetchInterestsData = async (queryClient: QueryClient) => {
	await Promise.allSettled([
		queryClient.prefetchQuery(getSiteInterestsBaseQueryOptions()),
		queryClient.prefetchQuery(getProfileInterestsBaseQueryOptions()),
	]);
};
