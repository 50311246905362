import {
	useQuery,
	useSuspenseQuery,
	type UseQueryOptions,
	type UseSuspenseQueryOptions,
	type UseQueryResult,
	type UseSuspenseQueryResult,
	queryOptions,
} from "@tanstack/react-query";

import { DEFAULT_SORT_DIRECTION, DEFAULT_TITLE_SORT_KEY } from "~/features/profile/constants";
import { type SortDirection } from "~/types/application";
import { directionalStringsSort } from "~/utilities/directional-sort/index.";

import { type GetInterestsResponse, getInterests } from "./interests.api";
import { type InterestsResponse } from "./interests.schema";

const getQueryKey = (sort: SortDirection = DEFAULT_SORT_DIRECTION) => ["interests", { sort }] as const;

const getBaseQueryOptions = <TData = GetInterestsResponse>() =>
	queryOptions<GetInterestsResponse, Error, TData>({
		queryFn: getInterests,
		queryKey: getQueryKey(),
	});

const useSiteInterestsQuery = <TData = GetInterestsResponse>(
	sortKey: "title" = DEFAULT_TITLE_SORT_KEY,
	sort: SortDirection = DEFAULT_SORT_DIRECTION,
	options?: Pick<UseQueryOptions<GetInterestsResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	useQuery<GetInterestsResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
		select: (data): TData => {
			const sortedData = directionalStringsSort(sort, structuredClone(data), sortKey);
			return sortedData as TData;
		},
	});

const useSiteInterestsSuspenseQuery = <TData = GetInterestsResponse>(
	sortKey: "title" = DEFAULT_TITLE_SORT_KEY,
	sort: SortDirection = DEFAULT_SORT_DIRECTION,
	options?: Pick<UseSuspenseQueryOptions<GetInterestsResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	useSuspenseQuery<GetInterestsResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
		select: (data) => directionalStringsSort(sort, data, sortKey) as TData,
	});

export {
	useSiteInterestsQuery,
	useSiteInterestsSuspenseQuery,
	getBaseQueryOptions as getSiteInterestsBaseQueryOptions,
	getQueryKey as getSiteInterestsQueryKey,
	type InterestsResponse,
};
