import {
	queryOptions,
	hashKey,
	useMutation as useBaseMutation,
	useSuspenseQuery as useBaseSuspenseQuery,
	type UseMutationOptions,
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	type UseMutationResult,
	type QueryKey,
} from "@tanstack/react-query";

import { ActivityProgress } from "~/enums";
import {
	buildApiUrl,
	buildQueryKeyUrlWithParams,
	type OptimisticContext,
	resetQueryData,
	getPreviousQueriesData,
	cancelQueries,
	getSafeQueryData,
	getCacheRemovalSet,
	handleNewCacheContentCreation,
	invalidateQueriesData,
} from "~/features/native/offline/utilities/optimistic-updates";
import { queryClient } from "~/lib/queryClient";
import { type SortDirection } from "~/types/application";
import { directionalDatesSort } from "~/utilities/directional-sort/index.";

import { type CompletedLearningResponse, getCompletedLearningQueryKey } from "../completed-learning/api";
import { getCompletedLearningUrl } from "../completed-learning/api/completed-learning.api";
import { type LeaderboardResponse } from "../leaderboard/api";
import { getOptimisticLeaderboardQueryData } from "../leaderboard/api/leaderboard.optimistic";
import { type ActivityTrackWithoutBadgeSource, toCompletedLearningItem } from "../utilities/transformers";

import {
	getActivities,
	getUrl,
	postActivities,
	type GetActivitiesResponse,
	type PostActivitiesResponse,
} from "./activities.api";
import {
	type Activity,
	type ActivityTrack,
	type ActivitiesResponse,
	type PostActivityVariables,
} from "./activities.schema";

type ActivitiesMutationOptions<TContext = unknown> = Omit<
	UseMutationOptions<PostActivitiesResponse, Error, ActivityTrack, TContext>,
	"mutationFn" | "mutationKey" | "onMutate"
>;

const optimisticUpdateUrlGetter = () => buildApiUrl(getUrl()).toString();

const optimisticCompletedLearningUpdateUrlGetter = (queryKey: QueryKey) =>
	buildQueryKeyUrlWithParams(getCompletedLearningUrl(), queryKey).toString();

const getQueryKey = () => ["activities"] as const;

const getBaseQueryOptions = <TData = GetActivitiesResponse>() =>
	queryOptions<GetActivitiesResponse, Error, TData>({
		queryFn: getActivities,
		queryKey: getQueryKey(),
		refetchOnMount: false,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: Infinity,
	});

const useSuspenseQuery = <TData = GetActivitiesResponse>(
	options?: Pick<UseSuspenseQueryOptions<GetActivitiesResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	useBaseSuspenseQuery<GetActivitiesResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});

const getMutationKey = () => ["activities", "activity"] as const;

const useMutation = <
	TContext extends OptimisticContext<ActivitiesResponse | CompletedLearningResponse | LeaderboardResponse>,
>(
	options?: ActivitiesMutationOptions<TContext>,
): UseMutationResult<PostActivitiesResponse, Error, PostActivityVariables, TContext> =>
	useBaseMutation<PostActivitiesResponse, Error, PostActivityVariables, TContext>({
		...options,
		mutationFn: postActivities,
		mutationKey: getMutationKey(),
		onError: async (_error, _newActivity, context) => {
			await resetQueryData(context!);

			options?.onError?.(_error, _newActivity, context);
		},
		onMutate: async (activity) => {
			const originalActivitiesQueries = queryClient.getQueriesData<ActivitiesResponse>({
				exact: true,
				queryKey: getQueryKey(),
				type: "all",
			});
			const previousActivitiesQueriesData = getPreviousQueriesData(
				originalActivitiesQueries,
				optimisticUpdateUrlGetter,
			);

			await cancelQueries(originalActivitiesQueries);

			const safeActivitiesQueries = getSafeQueryData(originalActivitiesQueries, getQueryKey(), []);

			const activitiesCacheRemovalData = getCacheRemovalSet(
				safeActivitiesQueries.noExistingCacheKeys,
				optimisticUpdateUrlGetter,
			);

			await handleNewCacheContentCreation(
				safeActivitiesQueries.safeData,
				([, queryData]) => [...queryData, activity],
				optimisticUpdateUrlGetter,
			);

			if (activity.activity === ActivityProgress.COMPLETE) {
				const originalCompletedLearningQueries = queryClient.getQueriesData<CompletedLearningResponse>({
					predicate: ({ queryHash }) =>
						queryHash === hashKey(getCompletedLearningQueryKey("ASC")) ||
						queryHash === hashKey(getCompletedLearningQueryKey("DESC")),
					type: "all",
				});
				const previousCompletedLearningQueriesData = getPreviousQueriesData(
					originalCompletedLearningQueries,
					optimisticCompletedLearningUpdateUrlGetter,
				);

				await cancelQueries(originalCompletedLearningQueries);

				const safeCompletedLearningQueries = getSafeQueryData(
					originalCompletedLearningQueries,
					getCompletedLearningQueryKey(),
					{
						count: 0,
						items: [],
					},
				);

				const completedLearningCacheRemovalData = getCacheRemovalSet(
					safeCompletedLearningQueries.noExistingCacheKeys,
					optimisticCompletedLearningUpdateUrlGetter,
				);

				const completedLearningOnSuccessInvalidationKeys = await handleNewCacheContentCreation(
					safeCompletedLearningQueries.safeData,
					([queryKey, queryData]) => {
						const newCompletedLearningItems = [
							...queryData.items,
							toCompletedLearningItem(activity as ActivityTrackWithoutBadgeSource),
						];

						const sortDirection = (queryKey.at(-1) as { sort: SortDirection }).sort;
						const sortedData = directionalDatesSort(
							sortDirection,
							newCompletedLearningItems,
							"completedAt",
						);

						return {
							count: newCompletedLearningItems.length,
							items: sortedData,
						} satisfies CompletedLearningResponse;
					},
					optimisticCompletedLearningUpdateUrlGetter,
				);

				const optimisticLeaderboardData = await getOptimisticLeaderboardQueryData(activity);

				return {
					onSuccessInvalidationKeys: [
						...completedLearningOnSuccessInvalidationKeys,
						...optimisticLeaderboardData.onSuccessInvalidationKeys,
					],
					previousQueries: [
						...previousActivitiesQueriesData,
						...previousCompletedLearningQueriesData,
						...optimisticLeaderboardData.previousQueries,
					],
					toRemoveOnError: [
						...activitiesCacheRemovalData,
						...completedLearningCacheRemovalData,
						...optimisticLeaderboardData.toRemoveOnError,
					],
				} as TContext;
			}

			return {
				onSuccessInvalidationKeys: [] as QueryKey[],
				previousQueries: previousActivitiesQueriesData,
				toRemoveOnError: activitiesCacheRemovalData,
			} as TContext;
		},
		onSuccess: (data, variables, context) => {
			invalidateQueriesData(context!.onSuccessInvalidationKeys);

			options?.onSuccess?.(data, variables, context);
		},
	});

export {
	useSuspenseQuery as useActivitiesSuspenseQuery,
	useMutation as useActivityMutation,
	getBaseQueryOptions as getActivitiesBaseQueryOptions,
	getQueryKey as getActivitiesQueryKey,
	getMutationKey as getActivityMutationKey,
	type Activity,
	type ActivitiesMutationOptions,
	type ActivityTrack,
	type ActivitiesResponse,
};
