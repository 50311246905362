export const isLocalStorageEnabled = () => {
	const storageKey = "is-local-storage-enabled-test";

	try {
		localStorage.setItem(storageKey, storageKey);
		localStorage.removeItem(storageKey);

		return true;
	} catch {
		return false;
	}
};
