import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";

import { missionsSchema } from "./missions.schema";

const getUrl = () => buildUrl("missions", 1);

const getMissions = () =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.get(getUrl())
		.json(missionsSchema.parseAsync);

type GetMissionsResponse = Awaited<ReturnType<typeof getMissions>>;

export { getMissions, getUrl, type GetMissionsResponse };
