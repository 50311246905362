import { type RouterEvent } from "@tanstack/react-router";

import { logError } from "~/lib/external/logging";
import { generateHexUuid } from "~/utilities/generate-hex-uuid";
import { isEmptyObject } from "~/utilities/is-empty-object";

import { applicationContext } from "../application/context";
import { router } from "../routing";

import { getSessionId } from ".";
import { postPageView } from "./api/page-view";
import { type PageviewEvent } from "./types";

let pageviewId = generateHexUuid();
let firstPageview = true;

const resetPageviewId = () => {
	pageviewId = generateHexUuid();
};

const getResolution = (): string => {
	return `${window.innerWidth}x${window.innerHeight}`;
};

const getTitle = () => {
	const matches = router.state.matches;
	for (const match of matches.slice().reverse()) {
		if (!isEmptyObject(match.staticData) && match.staticData.analyticsTitle) {
			return match.staticData.analyticsTitle;
		}
		if (match.context.analyticsTitle) {
			return match.context.analyticsTitle();
		}
		if (!isEmptyObject(match.staticData) && match.staticData.crumb) {
			return match.staticData.crumb();
		}
		const title = match.context.breadcrumbs?.crumb?.();
		if (title) {
			return title;
		}
	}
	return document.title;
};

const getTenant = () => {
	try {
		return applicationContext.tenant;
	} catch {
		return null;
	}
};

const handleRouterResolved = ({ pathChanged, toLocation }: RouterEvent) => {
	const tenant = getTenant();
	if (!pathChanged || !tenant) return;

	resetPageviewId();
	const url = new URL(window.location.href);
	const baseUrl = `${url.protocol}//${url.host}`;
	sendPageview(`${baseUrl}${toLocation.href}`);
};

const sendPageview = async (resource: string) => {
	try {
		const pageview = {
			connectionType: applicationContext.connection.connectionType,
			embeddedPlatform: "none",
			id: getPageviewId(),
			resolution: getResolution(),
			resource,
			timestamp: Date.now(),
			title: getTitle(),
			visit: getSessionId(),
		} as PageviewEvent;
		if (firstPageview) {
			firstPageview = false;
			pageview.referrer = document.referrer;
		}
		await postPageView(pageview);
	} catch (error) {
		logError(error, { extra: { debugInfo: "Analytics - page view" } });
	}
};

export const getPageviewId = () => pageviewId;

export const setupPageAnalytics = () => {
	const tenant = getTenant();
	if (tenant) {
		sendPageview(window.location.href);
	}
	router.subscribe("onResolved", handleRouterResolved);
};
