import { getCookie } from "@evotix/napier-ui-common-native-bridge/utilities";

import { TOKEN_COOKIE_NAME } from "~/features/application/constants";
import { hasAuthenticationCookie } from "~/utilities/has-authentication-cookie";

/**
 * Retrieves the bearer token from the cookie.
 * @returns {string} The bearer token or an empty string if the token cookie does not exist.
 * @example
 * const token = getBearerToken();
 * // Output: "Bearer <token>" if the token cookie exists, otherwise an empty string.
 */
export const getBearerToken = () => (hasAuthenticationCookie() ? `Bearer ${getCookie(TOKEN_COOKIE_NAME)}` : "");
