import {
	useSuspenseQuery as useBaseSuspenseQuery,
	queryOptions,
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
} from "@tanstack/react-query";

import { getAwards, type GetAwardsResponse } from "./awards.api";
import { type AwardsResponse, type Award } from "./awards.schema";

const getQueryKey = () => ["awards"] as const;

const getBaseQueryOptions = <TData = GetAwardsResponse>() =>
	queryOptions<GetAwardsResponse, Error, TData>({
		queryFn: getAwards,
		queryKey: getQueryKey(),
	});

const useAwardsSuspenseQuery = <TData = GetAwardsResponse>(
	options?: Pick<UseSuspenseQueryOptions<GetAwardsResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	useBaseSuspenseQuery<GetAwardsResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});

export {
	useAwardsSuspenseQuery,
	getQueryKey as getAwardsQueryKey,
	getBaseQueryOptions as getAwardsBaseQueryOptions,
	type Award,
	type AwardsResponse,
};
