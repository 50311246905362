import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { mutationSuccessResponse, type MutationSuccessResponse } from "~/lib/schemas/mutation-success";

import { type ActivitiesResponse, type ActivityTrack, activitiesSchema } from "./activities.schema";

const getUrl = () => buildUrl("profile/activities", 1);

const getActivities = (): Promise<ActivitiesResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.get(getUrl())
		.json(activitiesSchema.parse);

const postActivities = (data: ActivityTrack): Promise<MutationSuccessResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.post(data, getUrl())
		.json(mutationSuccessResponse.parse);

type GetActivitiesResponse = Awaited<ReturnType<typeof getActivities>>;
type PostActivitiesResponse = Awaited<ReturnType<typeof postActivities>>;

export { getActivities, postActivities, getUrl, type GetActivitiesResponse, type PostActivitiesResponse };
