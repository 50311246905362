import { type SortDirection } from "~/types/application";

import { getDateSortValue } from "../get-date-sort-value";

/**
 * Sorts an array of objects based on a string key in either ascending or descending order.
 * @template TItem - The type of items in the array, extending a record with a string key.
 * @template TSortKey - The key of the item to sort by, which must be of type string.
 * @param {SortDirection} sortDirection - The direction to sort, either "ASC" for ascending or "DESC" for descending.
 * @param {TItem[]} data - The array of objects to sort.
 * @param {TSortKey} sortKey - The key in the object to sort by, which contains a string value.
 * @returns {TItem[]} The sorted array.
 * @example
 * const data = [{ title: "b" }, { title: "a" }];
 * directionalStringsSort("ASC", data, "title"); // => [{ title: "a" }, { title: "b" }]
 */
export const directionalStringsSort = <TItem extends Record<TSortKey, string>, TSortKey extends keyof TItem>(
	sortDirection: SortDirection,
	data: TItem[],
	sortKey: TSortKey,
) =>
	sortDirection === "ASC"
		? data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]))
		: data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));

/**
 * Sorts an array of objects based on a date key in either ascending or descending order.
 * @template TItem - The type of items in the array, extending a record with a date key.
 * @template TSortKey - The key of the item to sort by, which must contain a date string.
 * @param {SortDirection} sortDirection - The direction to sort, either "ASC" for ascending or "DESC" for descending.
 * @param {TItem[]} data - The array of objects to sort.
 * @param {TSortKey} sortKey - The key in the object to sort by, which contains a date string value.
 * @returns {TItem[]} The sorted array.
 * @example
 * const data = [{ date: "2022-01-02" }, { date: "2022-01-01" }];
 * directionalDatesSort("ASC", data, "date"); // => [{ date: "2022-01-01" }, { date: "2022-01-02" }]
 */
export const directionalDatesSort = <TItem extends Record<TSortKey, string>, TSortKey extends keyof TItem>(
	sortDirection: SortDirection,
	data: TItem[],
	sortKey: TSortKey,
) =>
	sortDirection === "DESC"
		? data.sort((a, b) => getDateSortValue(a[sortKey], b[sortKey]))
		: data.sort((a, b) => getDateSortValue(b[sortKey], a[sortKey]));
