/**
 * Checks if an object is empty.
 * @template TObj - The type of the object.
 * @param {TObj} obj - The object to check.
 * @returns {boolean} - True if the object is empty, otherwise false.
 * @example
 * // Returns true
 * isEmptyObject({});
 * @example
 * // Returns false
 * isEmptyObject({name: 'John', age: 30});
 */
export const isEmptyObject = <TObj extends object>(obj: TObj | null | undefined): boolean => {
	if (obj === null || obj === undefined) {
		return true;
	}

	return Object.getOwnPropertyNames(obj).length === 0 && obj.constructor === Object;
};
