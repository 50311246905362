import { z } from "zod";

import { ActivityProgress, ActivitySourceType } from "~/enums";
import { type Prettify } from "~/types/application";

export const activitySchema = z.object({
	activity: z.nativeEnum(ActivityProgress).or(z.string()),
	points: z.number().catch(0),
	post_id: z.number(),
	smi: z.number().catch(0),
	source: z.nativeEnum(ActivitySourceType),
	timestamp: z.string().datetime({ offset: true }),
});

export type Activity = `${ActivityProgress}` | (string & {});

interface InitialActivityTrack extends z.infer<typeof activitySchema> {
	activity: Activity;
}

export type ActivityTrack = Prettify<InitialActivityTrack>;

export type PostActivityVariables = ActivityTrack & { title?: string | null };

export const activitiesSchema = z.array(activitySchema);

export type ActivitiesResponse = z.infer<typeof activitiesSchema>;
