import { z } from "zod";

import { PostType } from "~/enums";
import { cacheableMediaUrlSchema } from "~/lib/schemas/cacheable-media-url";

const profileInterestSchema = z.object({
	id: z.number(),
	interestIds: z.array(z.number()),
	thumbnailUrl: cacheableMediaUrlSchema().nullable(),
	title: z.string(),
	type: z.nativeEnum(PostType),
});

const profileInterestsSchema = z.array(profileInterestSchema).catch([]);

type ProfileInterestsResponse = z.infer<typeof profileInterestsSchema>;

export { profileInterestsSchema, type ProfileInterestsResponse };
