import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	useSuspenseQuery as useBaseSuspenseQuery,
	queryOptions,
} from "@tanstack/react-query";

import { type GetResourceResponse, getResource, getUrl } from "./resource.api";
import { type ResourceResponse } from "./resource.schema";

const getQueryKey = (id: number) => ["resources", "resource", { id }] as const;

const getBaseQueryOptions = <TData = GetResourceResponse>(id: number) =>
	queryOptions<GetResourceResponse, Error, TData>({
		queryFn: () => getResource(id),
		queryKey: getQueryKey(id),
	});

const useSuspenseQuery = <TData = GetResourceResponse>(
	id: number,
	options?: Pick<UseSuspenseQueryOptions<GetResourceResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> => {
	return useBaseSuspenseQuery<GetResourceResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(id),
	});
};

export {
	useSuspenseQuery as useResourceSuspenseQuery,
	getBaseQueryOptions as getResourceBaseQueryOptions,
	getQueryKey as getResourceQueryKey,
	getUrl as getResourceUrl,
	type ResourceResponse,
};
