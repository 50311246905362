import { z } from "zod";

import { AWARD_MAP, type AwardIcon } from "../constants";

const awardSchema = z.object({
	icon: z.enum(Object.keys(AWARD_MAP) as [AwardIcon]),
	id: z.number(),
	title: z.string(),
});

type Award = z.infer<typeof awardSchema>;

const awardsResponseSchema = z.array(awardSchema);

type AwardsResponse = z.infer<typeof awardsResponseSchema>;

export { awardsResponseSchema, type Award, type AwardsResponse };
