import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";

import { type InterestsResponse, interestsSchema } from "./interests.schema";

const getUrl = () => buildUrl("interests", 1);

const getSiteInterests = (): Promise<InterestsResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.get(getUrl())
		.json(interestsSchema.parseAsync);

type GetInterestsResponse = Awaited<ReturnType<typeof getSiteInterests>>;

export { getSiteInterests as getInterests, type GetInterestsResponse };
