import {
	useQuery as useBaseQuery,
	useMutation as useBaseMutation,
	queryOptions,
	type UseQueryResult,
	type UseQueryOptions,
	type UseMutationOptions,
	type QueryKey,
} from "@tanstack/react-query";

import {
	buildQueryKeyUrlWithParams,
	type OptimisticContext,
	resetQueryData,
	getPreviousQueriesData,
	cancelQueries,
	getSafeQueryData,
	getCacheRemovalSet,
	handleNewCacheContentCreation,
	invalidateQueriesData,
} from "~/features/native/offline/utilities/optimistic-updates";
import { type SortDirection } from "~/types/application";
import { directionalStringsSort } from "~/utilities/directional-sort/index.";

import {
	getUrl,
	getBookmarks,
	postBookmark,
	deleteBookmark,
	type GetBookmarksResponse,
	type PostBookmarkResponse,
	type DeleteBookmarkResponse,
} from "./bookmarks.api";
import { type Bookmark, type BookmarksResponse } from "./bookmarks.schema";
import { buildBookmarkMap, getBookmarkQueriesData } from "./utilities";

type MutationOptions<TData, TVariables, TContext> = Omit<
	UseMutationOptions<TData, Error, TVariables, TContext>,
	"mutationFn" | "mutationKey" | "onMutate" | "onSettled"
>;

const optimisticUpdateUrlGetter = (queryKey: QueryKey) => buildQueryKeyUrlWithParams(getUrl(), queryKey).toString();

const optimisticUpdateNewDataBuilder =
	(data: ReturnType<typeof buildBookmarkMap>) =>
	([queryKey]: [QueryKey, BookmarksResponse]) => {
		const bookmarks = Array.from(data.values());

		const sortDirection = (queryKey.at(-1) as { sort: SortDirection }).sort;
		const sortedData = directionalStringsSort(sortDirection, bookmarks, "dateModified");

		return { count: bookmarks.length, items: sortedData } satisfies BookmarksResponse;
	};

const getQueryKey = (sort: SortDirection = "DESC") => ["bookmarks", { sort }] as const;

const getMutationKey = () => ["bookmark"] as const;

const getBaseQueryOptions = <TData = GetBookmarksResponse>() =>
	queryOptions<GetBookmarksResponse, Error, TData>({
		queryFn: () => getBookmarks("DESC"),
		queryKey: getQueryKey("DESC"),
	});

const useQuery = <TData = GetBookmarksResponse>(
	options?: Pick<UseQueryOptions<GetBookmarksResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	useBaseQuery<GetBookmarksResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});

const usePostMutation = <TContext extends OptimisticContext<BookmarksResponse>>(
	options?: MutationOptions<PostBookmarkResponse, Bookmark, TContext>,
) =>
	useBaseMutation<PostBookmarkResponse, Error, Bookmark, TContext>({
		...options,
		mutationFn: postBookmark,
		mutationKey: getMutationKey(),
		onError: async (error, variables, context) => {
			await resetQueryData(context!);
			options?.onError?.(error, variables, context);
		},
		onMutate: async (bookmark) => {
			const originalQueriesData = getBookmarkQueriesData();
			const previousQueriesData = getPreviousQueriesData(originalQueriesData, optimisticUpdateUrlGetter);

			await cancelQueries(originalQueriesData);

			const safeQueries = getSafeQueryData(originalQueriesData, getQueryKey(), {
				count: 0,
				items: [],
			});

			const cacheRemovalData = getCacheRemovalSet(safeQueries.noExistingCacheKeys, optimisticUpdateUrlGetter);
			const bookmarkSet = buildBookmarkMap(safeQueries.safeData);

			bookmarkSet.set(bookmark.id, bookmark);

			const onSuccessInvalidationKeys = await handleNewCacheContentCreation(
				safeQueries.safeData,
				optimisticUpdateNewDataBuilder(bookmarkSet),
				optimisticUpdateUrlGetter,
			);

			return {
				onSuccessInvalidationKeys,
				previousQueries: previousQueriesData,
				toRemoveOnError: cacheRemovalData,
			} as TContext;
		},
		onSuccess: (data, variables, context) => {
			invalidateQueriesData(context.onSuccessInvalidationKeys);
			options?.onSuccess?.(data, variables, context);
		},
	});

const useDeleteMutation = <TContext extends OptimisticContext<BookmarksResponse>>(
	options?: MutationOptions<DeleteBookmarkResponse, number, TContext>,
) =>
	useBaseMutation<DeleteBookmarkResponse, Error, number, TContext>({
		...options,
		mutationFn: (id) => deleteBookmark(id),
		mutationKey: getMutationKey(),
		onError: async (error, variables, context) => {
			await resetQueryData(context!);
			options?.onError?.(error, variables, context);
		},
		onMutate: async (id) => {
			const originalQueriesData = getBookmarkQueriesData();
			const previousQueriesData = getPreviousQueriesData(originalQueriesData, optimisticUpdateUrlGetter);

			await cancelQueries(originalQueriesData);

			const safeQueries = getSafeQueryData(originalQueriesData, getQueryKey(), {
				count: 0,
				items: [],
			});

			const cacheRemovalData = getCacheRemovalSet(safeQueries.noExistingCacheKeys, optimisticUpdateUrlGetter);
			const bookmarkSet = buildBookmarkMap(safeQueries.safeData);

			bookmarkSet.delete(id);

			const onSuccessInvalidationKeys = await handleNewCacheContentCreation(
				safeQueries.safeData,
				optimisticUpdateNewDataBuilder(bookmarkSet),
				optimisticUpdateUrlGetter,
			);

			return {
				onSuccessInvalidationKeys,
				previousQueries: previousQueriesData,
				toRemoveOnError: cacheRemovalData,
			} as TContext;
		},
		onSuccess: (data, variables, context) => {
			invalidateQueriesData(context.onSuccessInvalidationKeys);
			options?.onSuccess?.(data, variables, context);
		},
	});

export {
	useQuery as useBookmarksQuery,
	usePostMutation as useAddBookmarkMutation,
	useDeleteMutation as useDeleteBookmarkMutation,
	getQueryKey as getBookmarksQueryKey,
	getBaseQueryOptions as getBookmarksQueryOptions,
	type BookmarksResponse,
};
