import { type QueryClient } from "@tanstack/react-query";

import { prefetchActivitiesData } from "~/features/activities/data-prefetch";
import { prefetchAwardsData } from "~/features/awards/data-prefetch";
import { prefetchBookmarksData } from "~/features/bookmarks/data-prefetch";
import { prefetchInterestsData } from "~/features/interests/data-prefetch";
import { prefetchLearningData } from "~/features/learning/data-prefetch";
import { prefetchResourceData } from "~/features/resources/data-prefetch";

export const prefetchApiData = async (queryClient: QueryClient) => {
	await Promise.allSettled([
		prefetchActivitiesData(queryClient),
		prefetchAwardsData(queryClient),
		prefetchBookmarksData(queryClient),
		prefetchInterestsData(queryClient),
		prefetchResourceData(queryClient),
		prefetchLearningData(queryClient),
	]);

	return Promise.resolve();
};
