import i18next, { changeLanguage } from "i18next";

import { dayjs } from "~/lib/external/day-js";
import { queryClient } from "~/lib/queryClient";
import { type Language } from "~/translations/constants";
import { isLocalStorageEnabled } from "~/utilities/is-local-storage-enabled";
import { splitString } from "~/utilities/split-string";

import { getLanguageStorageKey } from "../get-language-storage-key";

/**
 * Switch i18n language and save language to local storage against the current user
 * @param key - An iso language code
 * @example await switchLanguage("en-gb", 1234);
 */
export const switchLanguage = async (key: Language, userId?: number) => {
	// Exit out early if the new key is the same as what's already set
	if (key === i18next.language) return;

	await changeLanguage(key);
	dayjs.locale(key);

	document.documentElement.lang = splitString(key, "-")[0];

	// Make sure we cancel any ongoing queries and invalidate all queries
	/**
	 * TODO: rethink this logic, as it should only invalidate queries that are language dependent (learning/resources/completed-learning/bookmarks)
	 * and not invalidate application level queries (profile/site-config/awards...)*/
	queryClient.cancelQueries({ type: "all" }).then(() => queryClient.invalidateQueries({ type: "all" }));

	if (!userId || !isLocalStorageEnabled()) return;

	localStorage.setItem(await getLanguageStorageKey(userId), key);
};
