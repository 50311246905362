import { type QueryClient } from "@tanstack/react-query";

import { getLevelBaseQueryOptions, getLevelBaseQueryKey } from "../api/learning-group/level";
import { getMissionBaseQueryOptions, getMissionBaseQueryKey } from "../api/learning-group/mission";
import { getPathwayBaseQueryOptions, getPathwayBaseQueryKey } from "../api/learning-group/pathway";
import { type LearningGroupResponse } from "../api/learning.schema";
import { getMissionsBaseQueryOptions, type MissionsResponse, getMissionsQueryKey } from "../api/missions";
import { getPathwaysBaseQueryOptions, type PathwaysResponse, getPathwaysQueryKey } from "../api/pathways";
import { getStageBaseQueryOptions } from "../api/stage";

import { batchAndSettleRequests, buildPendingPrefetchPromises } from "./utilities";

export const prefetchLearningData = async (queryClient: QueryClient) => {
	// Prefetch slimmed data
	await Promise.allSettled([
		queryClient.prefetchQuery(getPathwaysBaseQueryOptions()),
		queryClient.prefetchQuery(getMissionsBaseQueryOptions()),
	]);

	let waterfallRequests: Promise<void>[] = [];

	// Prefetch data for missions (including third party) and pathways
	buildPendingPrefetchPromises<MissionsResponse>(
		queryClient,
		waterfallRequests,
		getMissionsQueryKey(),
		getMissionBaseQueryOptions,
	);
	buildPendingPrefetchPromises<PathwaysResponse>(
		queryClient,
		waterfallRequests,
		getPathwaysQueryKey(),
		getPathwayBaseQueryOptions,
	);

	await batchAndSettleRequests(waterfallRequests);
	waterfallRequests = [];

	// Initiate API requests for all potential content in learning journey

	// ORDER IS IMPORTANT TO ENSURE DATA IS AVAILABLE AT THE RIGHT TIME
	const prefetchQueryInfo = [
		[getPathwayBaseQueryKey, getLevelBaseQueryOptions],
		[getLevelBaseQueryKey, getMissionBaseQueryOptions],
		[getMissionBaseQueryKey, getStageBaseQueryOptions],
	] as const;

	for await (const [queryKeyGetter, queryOptionsGetter] of prefetchQueryInfo) {
		buildPendingPrefetchPromises<LearningGroupResponse>(
			queryClient,
			waterfallRequests,
			queryKeyGetter(),
			queryOptionsGetter,
		);

		await batchAndSettleRequests(waterfallRequests);
		waterfallRequests = [];
	}
};
