import { type ErrorInfo } from "react";
import * as Sentry from "@sentry/react";

type ErrorHint = Parameters<typeof Sentry.captureException>[1];

export function logError(error: unknown, errorInfo: ErrorInfo | ErrorHint) {
	if (isErrorInfo(errorInfo)) {
		return Sentry.captureException(error, {
			extra: { componentStack: errorInfo.componentStack },
		});
	}
	return Sentry.captureException(error, errorInfo);
}

const isErrorInfo = (errorInfo: ErrorInfo | ErrorHint): errorInfo is ErrorInfo =>
	errorInfo ? "componentStack" in errorInfo || "digest" in errorInfo : false;
