import { z } from "zod";

const siteInterestSchema = z.object({
	id: z.number(),
	title: z.string(),
});

const siteInterestsSchema = z.array(siteInterestSchema).catch([]);

type SiteInterestsResponse = z.infer<typeof siteInterestsSchema>;

const interestsSearchSchema = z.object({
	filters: z.array(z.number()).optional(),
});

export {
	siteInterestsSchema as interestsSchema,
	type SiteInterestsResponse as InterestsResponse,
	interestsSearchSchema,
};
