import { z } from "zod";

import { PostType } from "~/enums";
import { cacheableMediaUrlSchema } from "~/lib/schemas/cacheable-media-url";

import { baseLearningGroupChild } from "../shared.schema";

export const missionsSchema = z.array(
	baseLearningGroupChild.extend({
		ancestors: z
			.object({
				levelId: z.number(),
				pathwayId: z.number(),
			})
			.nullable()
			.catch(null),
		awardIds: z.array(z.number()).nullable().optional(),
		dueDate: z.string().datetime({ offset: true }).nullable(),
		thumbnailUrl: cacheableMediaUrlSchema(true).nullable().optional(),
		type: z.literal(PostType.MISSION),
	}),
);

export type MissionsResponse = z.infer<typeof missionsSchema>;
