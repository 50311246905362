import { buildUrl } from "~/features/application/utilities";
import { wretch } from "~/lib/external/wretch";
import { convertKeysToSnakeCase } from "~/utilities/camel-to-snake-case";

import { type PageviewEvent } from "../../types";

const getUrl = () => buildUrl("analytics/page-view", 1);

export const postPageView = (data: PageviewEvent) => {
	const payload = convertKeysToSnakeCase(data);
	return wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.post(convertKeysToSnakeCase(payload), getUrl())
		.res();
};
